<template>
  <div class="page-wrap">
    <div class="session-form-hold">
      <base-card :loading="loading" v-if="!promotion" class="rounded-xl">
        <v-card-text class="text-center">
          <img
            v-if="$vuetify.theme.dark"
            class="mb-4 mt-2"
            height="50"
            src="@/assets/images/logo/logo-light.png"
            alt=""
          />
          <img
            v-else
            class="mb-4 mt-2"
            height="50"
            src="@/assets/images/logo/logo-dark.png"
            alt=""
          />
          <template v-if="loading && !error">
            <v-alert
              border="left"
              class="text-left"
              text
              icon="mdi-information-outline"
              type="info"
            >
              Verificando promoção, por favor aguarde
            </v-alert>
          </template>
          <template v-else>
            <v-alert
              border="left"
              class="text-left"
              text
              icon="mdi-alert-circle-outline"
              type="error"
            >
              {{ error }}
            </v-alert>
            <v-btn
              color="secondary"
              class="mt-4"
              block
              :to="{
                name: 'sign-in',
              }"
            >
              Voltar
            </v-btn>
          </template>
        </v-card-text>
      </base-card>
      <div v-if="promotion">
        <v-card class="rounded-xl">
          <v-img
            class="rounded-t-xl"
            :src="party?.cover"
            :aspect-ratio="16 / 9"
          />

          <v-card
            elevation="0"
            :href="`/shop/${party.Organization.slug}/${party.slug || party.id}`"
            target="_blank"
            class="d-flex justify-space-between align-center pa-4"
          >
            <div>
              <span class="text-overline lh-1">
                {{ party.date | date("DD [de] MMM [de] YYYY [às] HH:mm") }}
              </span>
              <h5 class="text-h5 mb-0 font-weight-bold">{{ party.name }}</h5>
            </div>
            <v-btn icon small>
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-card>

          <v-divider class="my-0" />

          <div class="pa-4 d-flex flex-column gap-3">
            <base-md-viewer
              v-if="promotion.description"
              :value="promotion.description || ''"
            />

            <v-alert
              v-if="status === 'unavailable'"
              type="error"
              text
              class="mb-0"
            >
              Essa promoção não está mais disponível para resgate
            </v-alert>
            <v-card
              v-else
              class="rounded-lg d-flex align-center gap-3 pa-3"
              outlined
            >
              <v-icon size="30">mdi-ticket</v-icon>
              <div>
                <span
                  class="d-block lh-1 text-overline"
                  style="font-size: 10px !important"
                >
                  Ingresso disponível para resgate
                </span>
                <span class="text-14 font-weight-bold">
                  {{ promotion.TicketBlock.TicketGroup.name }}
                </span>
                <span class="d-block text-12">
                  {{ promotion.TicketBlock.name }}
                </span>
              </div>
            </v-card>

            <v-card
              v-if="userTickets.length > 0"
              outlined
              class="rounded-lg pa-3"
            >
              <h6>Meus resgates</h6>
              <div class="d-flex flex-column gap-2">
                <v-alert
                  v-for="(ticket, i) in userTickets"
                  :key="ticket.id"
                  @click="goToTicket(ticket.id)"
                  color="success"
                  text
                  class="mb-0"
                  dense
                  style="cursor: pointer"
                >
                  <div class="d-flex align-center gap-3">
                    <b class="text-20"> {{ i + 1 }}. </b>
                    <div class="flex-grow-1">
                      <b>
                        {{ promotion.TicketBlock.TicketGroup.name }}
                        •
                        {{ promotion.TicketBlock.name }}
                      </b>
                      <small class="d-block">
                        Resgatado em
                        {{
                          userTickets[0].createdAt
                            | date("DD/MM/YYYY [às] HH:mm")
                        }}
                      </small>
                    </div>
                    <v-icon color="success"> mdi-chevron-right </v-icon>
                  </div>
                </v-alert>
              </div>
            </v-card>

            <v-alert v-if="innerError" type="error" text class="mb-0">
              {{ innerError }}
            </v-alert>

            <div
              v-if="
                status === 'available' &&
                userTickets.length < promotion.maxPerUser
              "
            >
              <v-btn
                block
                color="primary"
                class="mt-4"
                :loading="redeemLoading"
                @click="startRedeem"
              >
                Resgatar ingresso
              </v-btn>
              <small class="d-block mt-2 text-center">
                Limite de {{ promotion.maxPerUser }} ingresso{{
                  promotion.maxPerUser > 1 ? "s" : ""
                }}
                por pessoa.
              </small>
              <small class="d-block text-body-2 mt-2 text-center">
                Ao resgatar, você concorda com os
                <a href="/terms/contract" target="_blank">termos de uso</a> e
                <a href="/terms/privacy" target="_blank">
                  política de privacidade</a
                >.
              </small>
            </div>
            <v-alert
              v-if="
                userTickets.length >= promotion.maxPerUser &&
                status === 'available'
              "
              type="error"
              text
              class="mb-0"
            >
              Você já resgatou o limite de {{ promotion.maxPerUser }} ingresso{{
                promotion.maxPerUser > 1 ? "s" : ""
              }}
              para essa promoção.
            </v-alert>
          </div>
        </v-card>
        <v-btn block href="/" text class="mt-2" color="white">
          ir para o app
        </v-btn>
      </div>
    </div>
    <require-sign-in v-if="!isAuthenticated" @success="successSign" />
    <re-captcha
      v-else
      ref="recaptcha"
      @error="recaptchaError"
      @close="recaptchaError"
      @success="redeemPromotion"
    ></re-captcha>
  </div>
</template>
<script>
import ReCaptcha from "@/components/global/ReCaptcha";
import REDEEM from "@/services/app/redeem";
import { mapGetters } from "vuex";
import DescriptionWrap from "@/components/global/DescriptionWrap.vue";
import RequireSignIn from "@/components/shop/modal/RequireSignIn.vue";

export default {
  name: "CourtesyPromotionalRedeem",
  metaInfo: {
    title: "Resgatar Cortesia",
  },
  data() {
    return {
      show: false,
      loading: true,
      error: false,
      redeemLoading: false,
      innerError: false,
      promotion: null,
      status: null,
      userTickets: null,
    };
  },
  methods: {
    recaptchaError() {
      this.redeemLoading = false;
      this.innerError =
        "Ocorreu ao validar o recaptcha, atualize a página e tente novamente.";
    },
    startRedeem() {
      if (!this.isAuthenticated) {
        this.$root.$emit("require-sign-in");
        return;
      }

      this.innerError = false;
      this.redeemLoading = true;
      this.$refs.recaptcha.execute();
    },
    async redeemPromotion(token) {
      try {
        this.redeemLoading = true;
        this.innerError = false;

        let partySlug = this.$route.params.partySlug;
        let promotionalId = this.$route.params.promotionalId;

        let { ticket } = await REDEEM.courtesyPromotional.redeem(
          partySlug,
          promotionalId,
          token
        );

        this.userTickets.push(ticket);
        this.redeemLoading = false;

        this.$confetti.start({
          defaultDropRate: 10
        });
        await new Promise((r) => setTimeout(r, 2500));
        this.$confetti.stop();
      } catch (e) {
        this.redeemLoading = false;
        this.innerError = e.message;
      }
    },

    async getPromotion() {
      try {
        let partySlug = this.$route.params.partySlug;
        let promotionalId = this.$route.params.promotionalId;

        let { courtesyPromotional, status, userTickets } =
          await REDEEM.courtesyPromotional.get(partySlug, promotionalId);
        this.loading = false;
        this.redeemLoading = false;
        this.promotion = courtesyPromotional;
        this.status = status;
        this.userTickets = userTickets;
      } catch (e) {
        this.loading = false;
        this.error = e.message;
      }
    },

    goToTicket(ticketId) {
      this.$router.push({
        name: "app.ticket.details",
        params: { id: ticketId },
      });
    },
  },
  computed: {
    ...mapGetters("app", ["tickets"]),
    ...mapGetters("auth", ["isAuthenticated", "user"]),
    party() {
      if (!this.promotion) return null;
      return this.promotion.TicketBlock.TicketGroup.Party;
    },
  },
  mounted() {
    let id = this.$route.params.promotionalId;
    if (!id) {
      this.loading = false;
      this.error = "O link não é válido";
      return;
    }
    this.getPromotion();
  },
  components: {
    ReCaptcha,
    RequireSignIn,
    DescriptionWrap,
  },
};
</script>
<style lang="scss" scoped>
.page-wrap {
  background: radial-gradient(
      circle at 50% 50%,
      rgba(0, 0, 0, 50%) 0%,
      rgba(0, 0, 0, 90%) 90vw
    ),
    url(/img/logo/auth-background.png), black;
  display: flex;
  background-size: cover;
  background-position: center;
  align-items: center;
  padding: 40px 1rem;
  height: 100%;
  min-height: 100vh;
}
.session-form-hold {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}
</style>
