import http from "@/http-common";

const courtesyPromotionalGet = async (partySlug, promotionalId) => {
  return await http
    .get(`/app/redeem/courtesy-promotional/${partySlug}/${promotionalId}`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const courtesyPromotionalRedeem = async (partySlug, promotionalId, recaptcha) => {
  return await http
    .post(`/app/redeem/courtesy-promotional/${partySlug}/${promotionalId}`, { recaptcha })
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

export default {
  courtesyPromotional: {
    get: courtesyPromotionalGet,
    redeem: courtesyPromotionalRedeem,
  },
};
